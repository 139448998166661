<template>
  <div class="g_page_box box">
    <g-loading :loading="loading" />
    <div :class="['detail','g_main_content']" >
      <div class="head">
        <div class="bg"/>
        <div class="text1">
          {{name}}
          <div>{{text}}</div>
        </div>
        <div class="process">
          <div v-for="(i,index) in list" :key="index" :class="['item',{'item-2': index===0},{active: i.value<=state}]">
            <div :class="['yuan',{'active-yuan': state>=i.value}]"/>
            <div :class="['line',{'active-line': state>=i.value}]"/>
            <div :class="{'label-position':index!==list.length-1&&index!==0}">{{i.label}}</div>
          </div>
        </div>
      </div>
      <div class="content">
         <!-- 等待检验检查流程提示 -->
        <template>
          <div class="title">请至{{detail_data.org_name}}做相关{{item_type}}</div>
            <div class="part-1" v-if="detail_data.org_name">
              <Map @distance="set_distance" :name="detail_data.org_name" :default_loation="detail_data" :geolocation="geolocation"/>
            </div>
            <div class="location-label" v-if="detail_data.org_name">
              <div class="location-text" @click="getMapApp">
                <img width="16" height="16" src="../../assets/img/order/location.png"/>
                <div class="label">
                  {{detail_data.org_name}}
                  <div>{{detail_data.org_adress}}</div>
                </div>
              </div>
              距离{{distance/1000}}km
            </div>
        </template>
        <!-- 检验流程显示和预约提交之后流程 -->
        <template v-if="reservation_state>1">
            <div class="title-2">{{item_type}}项目</div>
            <ul class="part-2">
              <li v-for="i in order_arr" :key="i.item_id" class="lab-i">
                <div class="name">
                  {{i.name}}
                  <div>{{i.item_desc}}</div>
                </div>
                <div :class="['state',{'finish-state':i.lab_report_state === 4}]">{{hander_state(i.lab_report_state)}}</div>
              </li>
            </ul>
        </template>
        <!-- 等待检验检查流程提示 -->
        <template v-if="reservation_state === 2&&info_arr.length>0">
          <div class="title-2">{{item_type}}须知</div>
          <div class="part-3" v-for="(i,index) in info_arr" :key="index">
            {{index+1}}. {{i.content}}
          </div>
        </template>
      </div>
    </div>
    <!-- <div class="foot">
      <div class="foot-button" v-if="reservation_state===2" @click="to_card">显示健康卡</div>
      <div class="foot-button" @click="look_report" v-if="is_show&&false">报告解读</div>
    </div> -->
  </div>
</template>
<script>
import{mapState,mapActions} from 'vuex'
import {get_order,get_info} from './service'
import {format_date} from '../../utils/format'
import Map from './Map'
export default {
  data () {
    return {
      order_id: '',
      order_type: null,
      loading: false,
      reservation_state: null,
      list: [],
      text: '',
      item_type: '',
      state: null,
      active: 0,
      order_arr: [],
      name: '',
      detail_data: {},
      distance: 0,
      info_arr: []
    }
  },
  computed:{
    timestamp() {
      return this.format_date(this.now_date)
    },
    ...mapState('commonData', {
      geolocation: state => state.geolocation
    }),
    is_show() {
      let show = false
      if(this.order_type === 1) {
        for(let i of this.detail_data.items || []){
          if(i.lab_report_state&& i.lab_report_state===4){
            show = true
            break
          }
        }
      }
      return show
    },
  },
  components: {
    Map
  },
  watch: {
    onConfirm(date) {
      this.show = false;
      this.date = date;
    },
    now_date(val) {
      let time = this.format_date(val)
      this.date_obj[time] = this.organizations
      this.order_obj = this.date_obj[time][0]
      this.$nextTick(()=>{
        this.$refs.scroll.scrollLeft = 0
      })
    },
  },
  created() {
    let {order_id} = this.$route.query
    this.order_id = order_id
    this.search_detail()
  },
  methods: {
    ...mapActions('commonData', [
      'get_geolocation'
    ]),
    format_date,
    set_distance(val) {
      this.distance = val
    },
    hander_arr(array) {
      let obj = {}
      for(let item of array) {
        for (let el of item.examinations) {
          let val = JSON.parse(JSON.stringify(el))
          obj[val.order_id] = val
        }
      }
      this.order_arr = obj
    },
    hander_text(data) {
      let reservation_state = data.report_state
      let name = +data.order_type === 1 ? '检验' : '检查'
      this.item_type = name
      this.state = +reservation_state=== 3 ? 2 : +reservation_state
      this.reservation_state = +reservation_state
      let text = ''
      let text1 = ''
      let list = []
      switch(+reservation_state) {
      case 1:
        text = '选择您最近的医院进行预约'
        text1 = `预约${name}`
        list = [{label:`预约${name}`,value: 1},{label:`等待${name}`,value: 2},{label:'等待报告',value: 3}]
        break
      case 2:
        text = `请按照下面指示尽快进行${name}`
        text1 = `等待${name}`
        list = [{label:`预约${name}`,value: 1},{label:`等待${name}`,value: 2},{label:'等待报告',value: 3}]
        break
      case 3:
        text = '我们实时关注您的检查结果，出报告时第一时间通知到您'
        text1 = '等待报告'
        list = [{label: '已预约',value: 1},{label:`已${name}`,value: 2},{label:'等待报告',value: 3}]
        break
      case 4:
        text = '您可以通过报告解读，分析病情和在线就诊'
        text1 = '报告已完成'
        list = [{label:`等待${name}`,value: 1},{label:`已${name}`,value: 2},{label:'已出报告',value: 4}]
        break
      default:
        text = ''
      }
      this.text = text
      this.name = text1
      this.list = list
    },
    hander_state(val) {
      let text = ''
      switch(val) {
      case 1:
        text = '等待预约'
        break
      case 2:
        text = '等待检查'
        break
      case 3:
        text = '等待报告'
        break
      case 4:
        text = '已出报告'
        break
      default:
        text = ''
      }
      return text
    },
    look_report () {
      this.$router.push({
        path: '/report',
        query: {
          order_id: this.order_id
        }
      })
    },
    to_card() {
      // this.$router.push({
      //   path: '/card',
      //   query: {}
      // })
    },
    getMapApp() {
      const { latitude, longitude, org_adress} = this.detail_data
      let url = 'https://uri.amap.com/marker?position='+ longitude + ',' + latitude +'&name='+ org_adress +'&callnative=1';
      window.location.href = url
    },
    async search_detail () {
      try {
        this.loading = true
        const {data} = await get_order({sub_order_id:this.order_id})
        let ids = []
        for(let i of data.items) {
          ids.push(i.code)
        }
        this.search_info(ids)
        this.detail_data = data
        this.order_arr = data.items
        this.hander_text(data)
      } finally  {
        this.loading = false
      }
    },
    async search_info (ids) {
      try {
        this.loading = true
        const {data} = await get_info({ids})
        let arr = []
        for(let i of Object.values(data)) {
          for(let item of i) {
            if(item.categeory === '检查前须知' || item.categeory === '注意事项') {
              arr.push(item)
            }
          }
        }
        this.info_arr = arr
      } finally  {
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
.detail {
  padding-bottom: 50px;
}
.head {
  padding: 25px 23px 24px 23px;
  height: 166px;
  position: relative;
  background: #000CA8;
  display: flex;
  flex-direction: column;
  & .text1 {
    flex:1;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    color: #FFFFFF;
    & >div {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #FFFFFF;
    }
  }
  & .bg {
    width: 106px;
    height: 100%;
    background: url('../../assets/img/order/detail-bg.png') center center no-repeat;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  & .process {
    position: relative;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    margin-left: 15px;
    & .item {
      position: relative;
      flex:1;
      color: #fff;
      text-align: right;

      & .label-position {
        display: inline-block;
        transform: translateX(25%)
      }
      & .yuan {
        position: absolute;
        top: -15px;
        right: 0px;
        z-index: 1;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-radius: 50%;
        &.active-yuan {
          background-color: rgba(255, 230, 35, 1);
        }
      }
      & .line {
        position: absolute;
        top: -15px;
        right: 0px;
        width: 100%;
        height: 2px;
        background-color:rgba(139, 149, 158, 1);
        &.active-line {
          background-color:rgba(255, 230, 35, 1);
        }
      }
      &.item-2{
        position: absolute;
        left: -15px;
       & .yuan {
          right: auto;
          left: 0;
       }
      }
      &.active{
        color: rgba(255, 230, 35, 1);
      }
    }
  }
}
.content {
  padding: 0px  20px 30px;
  margin-top: 20px;
  background: #fff;
  & .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #242938;
    padding-bottom: 14px;
  }
  & .part-1 {
    height: 130px;
    background: rgba(0, 0, 0, 0);
    border:1px solid #ddd;
  }
  & .location-label {
    padding-top: 16px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    color: #8B959E;
    padding-bottom: 12px;
    & .location-text {
      flex: 1;
      display: flex;
      & >img {
        margin-right: 9px;

      }
      & .label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #242938;
        & >div{
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
  & .title-2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: #242938;
    padding: 12px 0 9px;
  }
  & .part-2 {
    & .lab-i {
      padding: 14px 17px;
      min-height: 65px;
      background: rgba(231, 241, 253, 0.44);
      border-radius: 10px;
      margin-bottom:14px;
      display: flex;
      align-items: center;
      & .name {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #242938;
          flex:1;
        & >div {
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          color: #454545;
        }
      }
      & .state {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #0088FF;
        padding-left: 15px;
        & .finish-state {
          color: rgba(9, 187, 7, 1);
        }
      }
    }
  }
  & .part-3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #242938;
  }
}
.foot {
  height: 64px;
  background: #FFFFFF;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  padding-top: 11px;
  position: relative;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 1 solid #ebedf0;
    transform: scale(0.5);
  }
  & .foot-button {
    background: #0088FF;
    height: 40px;
    font-size: 16px;
    border-radius: 9px;
    color: #FFFFFF;
    font-weight: 500;
    width: 335px;
    display: flex;
   justify-content: center;
   align-items: center;
   &.disable-button{
     opacity: 0.5;
     pointer-events: none;
   }
  }
}

</style>
