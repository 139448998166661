<template>
  <div id="container">

  </div>
</template>
<script>

export default {
  data() {
    return {
      amap: null,
      infoWindow: null
    }
  },
  props:{
    name: {
      default: '',
      type: String
    },
    default_loation: {
      default: ()=>{},
      type: Object
    },
    geolocation: {
      default: ()=>{},
      type: Object
    }
  },
  computed:{
    loation_start() {
      return [this.geolocation && this.geolocation.lng, this.geolocation && this.geolocation.lat]
    },
    loation_end() {
      return [this.default_loation.longitude,this.default_loation.latitude]
    }
  },
  watch: {
    geolocation() {
      this.search_distance(this.loation_start,this.loation_end)
    }
  },
  created() {
  },
  mounted() {
    this.init_map()
  },
  methods: {
    init_map() {
      this.amap = new AMap.Map("container", {
        zoom: 13,
        center: this.loation_end,
        resizeEnable: true
      })
      this.infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, -15)})
      this.mark_map()
      if(this.geolocation) this.search_distance(this.loation_start,this.loation_end)
    },
    mark_map(){
      var markerContent =
      `<div class="custom-content-marker">
        <img width="30" src="./img/order/luohu.png">
      </div>`
      var marker = new AMap.Marker({
        position: this.amap.getCenter(),
        content: markerContent,
        offset: new AMap.Pixel(-13, -10)
      })
      marker.setMap(this.amap)
      this.infoWindow.setContent(this.name)
      this.infoWindow.open(this.amap, marker.getPosition())
    },
    search_distance(startLngLat,endLngLat) {
      console.log(startLngLat,endLngLat)
      let that = this
      AMap.plugin('AMap.Driving',function(){
        var driving = new AMap.Driving({
          policy: AMap.DrivingPolicy.LEAST_TIME
        })
        driving.search(startLngLat,endLngLat, function(status, result) {
          if (status === 'complete') {
            that.$emit('distance',result.routes[0].distance)
          } else {
            console.log('获取驾车数据失败：' + result)
          }
        });
      })

    }
  }
}
</script>
<style scoped>
#container {
  width: 100%;
  height: 100%;
}
>>>.amap-logo {
  display: none !important;
}
>>>.amap-mcode  {
  display: none !important;
}
>>>.amap-copyright {
  display: none !important;
}
>>>.amap-info-close {
  display: none;
}
</style>


